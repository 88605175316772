<script lang="ts" setup>
import { ref, computed, watch, getCurrentInstance } from "vue";
import HomesGrid from "@/components/app/homes/HomesGrid.vue";
import { getBuilding, getSubLocationsFilterOptions, severityCode } from "@/services/api/building.api";
import LocationMap from "@/components/general/LocationMap.vue";
import { getSubLocationsFilterToken, getSubLocationToken } from "@/services/api/token.api";
import { FilterTokenName, FilterStatus, mapFilterResponseStatus } from "@/stores/filtertoken";
import handleFilterTokens27 from "@/composables/handleFilterTokens27";
import StartingPointWidget from "@/components/app/homes/StartingPointWidget.vue";
import useMessages from "@/composables/messages.js";
import { labels } from "@/composables/miscellaneous.js";

const { filterToken, mapFilterResult, createFilterToken, resetFilterToken } = handleFilterTokens27(FilterTokenName.ComplexDetails);
const { msgDataScore } = useMessages();

const props = defineProps({
  addressId: {
    type: String,
    default: "",
  },
});

const refreshAppliedKey = ref(0);
const isLoading = ref(false);

const building = ref({
  locationReference: "",
  description: "",
  address: "",
  number: "",
  postalCode: "",
  city: "",
  ecmFunctionCode: undefined,
  ecmFunctionName: "",
  weiiBuildingDecree: "",
  weiiBuildingCategory: "",
  squareMeters: "",
  dataScore: {
    percent: undefined,
    messages: [],
  },
  parisProofScore: undefined,
  parisProofNorm: undefined,
  energyLabelIsEstimate: false,
  country: "",
  code: "",
  energyLabel: "-",
  operationalLabel: "-",
  colorLabel: "-",
  colorOperationalLabel: "-",
  energyIndex: "",
  energyDemand: "",
  primaryFossilEnergyUse: "",
  shareOfRenewableEnergy: "",
  labelSource: "",
  bagBld: "",
  bagObj: "",
  buildYear: "",
  occupRate: "",
  hourOfUse: "",
  usageTime: "",
  usageStatus: "",
  gbsType: "",
  openingDate: "",
  closingDate: "",
  energySources: "",
  eedDate: "",
  bspRealised: "",
  lat: 0,
  lon: 0,
  mapdata: "",
  energetics: {
    parts: [],
  },
  isMonumental: "",
  isRenewEnergyUsed: "",
  shareOfRenewElecPerc: "",
  shareOfRenewEnergyPerc: "",
  shareOfRenewHeatPerc: "",
  installations: {
    ventType: "",
    humidType: "",
    heatType: "",
    coolType: "",
    warmwatType: "",
    manageSys: "",
    lightType: "",
    avgWattLights: 0,
    solarAmount: "",
    solarType: "",
  },
});

const filters = ref([
  {
    name: "Behoefte",
    fieldName: "demandHeatNet",
    type: "range",
    options: [],
    status: FilterStatus.None,
  },
  {
    name: "Norm",
    fieldName: "energyStandard",
    type: "range",
    options: [],
    status: FilterStatus.None,
  },
  {
    name: "Referentie",
    fieldName: "reference",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
  {
    name: "Omschrijving",
    fieldName: "descr",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
  {
    name: "Adres",
    fieldName: "address",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
  {
    name: "Plaats",
    fieldName: "city",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
  {
    name: "Oppervlakte",
    fieldName: "sqm",
    type: "range",
    options: [],
    status: FilterStatus.None,
  },
  {
    name: "Datascore",
    fieldName: "dataScore",
    type: "range",
    options: [],
  },
  {
    name: "Energielabel",
    fieldName: "label",
    type: "list",
    options: [...labels],
  },
]);

const hasDataAvailabilityMessages = computed(() => !building.value.dataScore.messages);

const complexToken = ref(undefined);

const subLocationToken = computed(() => (filterToken.value.id ? filterToken.value.id : complexToken.value));

watch(
  () => props.addressId,
  () => {
    fetchData();
    loadAddress();
  },
  {
    immediate: true,
  }
);

async function loadAddress() {
  let response = await getSubLocationToken(props.addressId);
  console.log("ComplexDetails, loadAddress ", response);
  if (response.severity <= severityCode.warning) {
    complexToken.value = response.data.id;
  }
}

async function fetchData() {
  isLoading.value = true;
  const response = await getBuilding(props.addressId);
  if (response.severity <= severityCode.warning) {
    console.log("ComplexDetails, fetchData, response: ", props.addressId, response.data);
    building.value = response.data;
  }
  isLoading.value = false;
}

async function handleFilterSearch(index, text) {
  let result = [];
  let status = FilterStatus.None;
  switch (filters.value[index].fieldName) {
    case "label":
      result = filters.value[index].options;
      break;
    default:
      if (text && text.length > 0) {
        const response = await getSubLocationsFilterOptions(subLocationToken.value, filters.value[index].fieldName, text);
        status = mapFilterResponseStatus(response.severity, response.code);
        if (status === FilterStatus.Success) {
          result = response.data.data;
        } else {
          if (status === FilterStatus.Error) {
            proxy.$toaster.error("Ophalen van de data is mislukt! " + response.message);
          }
        }
      }
      break;
  }
  filters.value[index].status = status;
  filters.value[index].options = mapFilterResult(result);
}

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function handleFiltersChanged(filterData) {
  //console.log("Buildingoverview handleFiltersChanged filterData: ", filterData);
  if (filterData.length > 0) {
    const getFilterToken = getSubLocationsFilterToken(subLocationToken.value, filterData);
    const success = await createFilterToken(getFilterToken);
    console.log("ComplexDetails handleFiltersChanged token: ", filterToken);
    if (!success) {
      proxy.$toaster.error("Ophalen van de filter token is mislukt!");
    }
  } else {
    resetFilterToken();
  }
}
function startingpointUpdated() {
  console.log("ComplexDetails, startingpointUpdated ");
  refreshAppliedKey.value++;
  fetchData();
}
</script>

<template>
  <v-container fluid pa-0 fill-height>
    <v-row>
      <v-col lg="3" md="4" sm="12" order="2" order-md="1" class="app-paris-proof-sidebar">
        <complex-net-heat-requirement :addressId="addressId"></complex-net-heat-requirement>
      </v-col>
      <v-col lg="9" md="8" sm="12" order="1" order-md="2" class="app-complex-detail">
        <v-row>
          <v-col cols="12" lg="7" class="building-info">
            <v-container class="inner">
              <header>
                <h1 class="small">{{ building.description }}</h1>
                <strong>{{ building.ecmFunctionName }}</strong>
              </header>
              <v-row>
                <v-col>
                  <ul class="building-details">
                    <li>{{ building.address }}</li>
                    <li>{{ building.postalCode }} {{ building.city }}</li>
                    <li>{{ building.country }}</li>
                  </ul>
                  <v-container pa-0>
                    <v-row noGutters class="energy-scores">
                      <v-col>
                        <p>Operationeel</p>
                        <energy-label :label="building.operationalLabel" :estimate="building.energyLabelIsEstimate"></energy-label>
                      </v-col>

                      <v-col>
                        <p>Energielabel</p>
                        <energy-label :label="building.energyLabel" :estimate="building.energyLabelIsEstimate"></energy-label>
                      </v-col>

                      <!-- het circeltje van de widget ontbreekt als er een tooltip wordt gebruikt -->
                      <v-col>
                        <p>Datascore</p>
                        <div v-show="isLoading === false">
                          <div v-show="hasDataAvailabilityMessages">
                            <v-tooltip right>
                              <template v-slot:activator="{ on }">
                                <div v-on="on" class="widget-chart building-data-score" style="cursor: help">
                                  <percentage-chart :width="68" :height="68" :percentage="building.dataScore.percent" />
                                </div>
                              </template>
                              <div>
                                <p>Dit ontbreekt er nog</p>
                                <ul>
                                  <li v-for="(msg, index) in building.dataScore.messages" :key="index">{{ msgDataScore(msg) }}<br /></li>
                                </ul>
                              </div>
                            </v-tooltip>
                          </div>
                          <div v-show="!hasDataAvailabilityMessages" class="widget-chart building-data-score">
                            <percentage-chart :width="68" :height="68" :percentage="building.dataScore.percent" />
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
                <v-col col="5">
                  <loader-spinner v-if="isLoading === true" :is-loading="isLoading"></loader-spinner>
                  <location-map v-else-if="building.mapdata" :locationmapdata="building.mapdata"></location-map>
                </v-col>
              </v-row>
            </v-container>
          </v-col>

          <v-col cols="12" lg="5" class="startingpoint">
            <v-layout class="inner">
              <starting-point-widget :partyAddressableObjectId="addressId" @update-startingpoint="startingpointUpdated"></starting-point-widget>
            </v-layout>
          </v-col>
        </v-row>

        <v-row class="overview-wrapper">
          <v-col>
            <building-applied-overview :addressId="addressId" :ecmFunctionCode="building.ecmFunctionCode" :ecmFunctionName="building.ecmFunctionName" :key="refreshAppliedKey">
            </building-applied-overview>
          </v-col>
        </v-row>

        <v-row class="overview-wrapper">
          <v-col>
            <article class="inner">
              <!-- <v-row>
                <v-col>
                  <header><h4>Woningen filteren</h4></header>
                </v-col>
              </v-row> -->

              <div class="overview-filters align-items-start mt-4">
                <filter-set :name="FilterTokenName.ComplexDetails" :filters="filters" @filter-search="handleFilterSearch" @filters-changed27="handleFiltersChanged"></filter-set>
              </div>
              <homes-grid v-if="subLocationToken" :token="subLocationToken"></homes-grid>
            </article>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
