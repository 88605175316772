var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { scrollable: "", "max-width": "600" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    { staticClass: "primary", attrs: { dark: "" } },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c("v-icon", { attrs: { dark: "", left: "" } }, [
                    _vm._v("mdi-update"),
                  ]),
                  _vm._v(" Maatregelen genereren "),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _setup.dialog,
        callback: function ($$v) {
          _setup.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-form",
        [
          _c(
            "v-card",
            { staticClass: "mx-auto" },
            [
              _c(
                "v-card-title",
                [
                  _c("header", { staticClass: "app-welcome-message" }, [
                    _c("h2", [_vm._v("Maatregelen berekenen")]),
                  ]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          return _setup.onCancel()
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { dark: "" } }, [
                        _vm._v("mdi-close"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  label: "Ambitie",
                                  items: _setup.spAmbitionsList,
                                  outlined: "",
                                  "item-text": "descr",
                                  "item-value": "key",
                                  "hide-details": "",
                                  "persistent-placeholder": "",
                                  placeholder: "Kies een ambitie",
                                },
                                model: {
                                  value: _setup.ambitionKey,
                                  callback: function ($$v) {
                                    _setup.ambitionKey = $$v
                                  },
                                  expression: "ambitionKey",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  label: "Strategie",
                                  items: _setup.spStrategiesList,
                                  outlined: "",
                                  "item-text": "descr",
                                  "item-value": "key",
                                  "hide-details": "",
                                  "persistent-placeholder": "",
                                  placeholder: "Kies een strategie",
                                },
                                model: {
                                  value: _setup.strategyKey,
                                  callback: function ($$v) {
                                    _setup.strategyKey = $$v
                                  },
                                  expression: "strategyKey",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  label: "Randvoorwaarde",
                                  items: _setup.spConditionsList,
                                  outlined: "",
                                  "item-text": "descr",
                                  "item-value": "key",
                                  "hide-details": "",
                                  "persistent-placeholder": "",
                                },
                                model: {
                                  value: _setup.conditionKey,
                                  callback: function ($$v) {
                                    _setup.conditionKey = $$v
                                  },
                                  expression: "conditionKey",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-container",
                    [
                      _setup.inputChanged
                        ? _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-alert",
                                    {
                                      attrs: {
                                        dense: "",
                                        border: "left",
                                        type: "warning",
                                      },
                                    },
                                    [
                                      _vm._v("Let op! "),
                                      _c("br"),
                                      _vm._v(
                                        "De bestaande maatregelen worden verwijderd door deze actie. "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "primary",
                                  attrs: {
                                    title: "Opslaan",
                                    dark:
                                      _setup.inputChanged &&
                                      !_setup.disableButtons,
                                    disabled:
                                      !_setup.inputChanged ||
                                      _setup.disableButtons,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _setup.onSubmit()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { dark: "", left: "" } },
                                    [_vm._v("mdi-checkbox-marked-circle")]
                                  ),
                                  _vm._v(" Berekenen en opslaan "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    title: "Annuleren",
                                    disabled: _setup.disableButtons,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _setup.onCancel()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { dark: "", left: "" } },
                                    [_vm._v("mdi-cancel")]
                                  ),
                                  _vm._v(" Annuleren "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }