var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { staticClass: "widget-item" },
    [
      _c("header", [
        _c("h2", [_vm._v("Uitgangspunten maatregelen")]),
        _c(
          "div",
          { staticClass: "widget-actions" },
          [
            _c(
              "information-dropdown",
              {
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function () {
                      return [_c("h2", [_vm._v("Uitgangspunten maatregelen")])]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _c("p", [
                  _vm._v(
                    "Geef hier uw ambitie, strategie en randvoorwaarde op. De slimme rekenkern berekent het meest optimale pakket om dit complex te verduurzamen."
                  ),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
      _c(
        "v-container",
        { attrs: { "pa-0": "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "4" } }, [
                        _c("strong", [_vm._v("Ambitie")]),
                      ]),
                      _c("v-col", [_vm._v(_vm._s(_setup.spAmbitionDescr))]),
                    ],
                    1
                  ),
                  _c("v-row", [_c("v-col", [_c("v-divider")], 1)], 1),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "4" } }, [
                        _c("strong", [_vm._v("Strategie")]),
                      ]),
                      _c("v-col", [_vm._v(_vm._s(_setup.spStrategyDescr))]),
                    ],
                    1
                  ),
                  _c("v-row", [_c("v-col", [_c("v-divider")], 1)], 1),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "4" } }, [
                        _c("strong", [_vm._v("Randvoorwaarde")]),
                      ]),
                      _c("v-col", [_vm._v(_vm._s(_setup.spConditionDescr))]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col"),
              _c(
                "v-col",
                [
                  _c(_setup.StartingPointsModal, {
                    attrs: {
                      partyAddressableObjectId:
                        _setup.props.partyAddressableObjectId,
                      ambition: _setup.spAmbitionKey,
                      strategy: _setup.spStrategyKey,
                      condition: _setup.spConditionKey,
                    },
                    on: { "update-startingpoint": _setup.startingpointUpdated },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }