<script lang="ts" setup>
import { ref, watch, getCurrentInstance } from "vue";

import StartingPointsModal from "@/components/app/homes/StartingPointsModal.vue";
import { getStartingPoint } from "@/services/api/building.api";

const emits = defineEmits(["update-startingpoint"]);

const props = defineProps({
  partyAddressableObjectId: {
    type: String,
  },
});

const spAmbitionKey = ref(undefined);
const spAmbitionDescr = ref(undefined);

const spStrategyKey = ref(undefined);
const spStrategyDescr = ref(undefined);

const spConditionKey = ref(undefined);
const spConditionDescr = ref(undefined);

watch(
  () => props.partyAddressableObjectId,
  () => {
    fetchData();
  },
  { immediate: true }
);

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function fetchData() {
  console.log("StartingPointWidget, fetchData ", props.partyAddressableObjectId);
  if (props.partyAddressableObjectId) {
    const response = await getStartingPoint(props.partyAddressableObjectId);
    console.log("StartingPointWidget, fetchData response ", response.data);

    if (response && !response.error) {
      spAmbitionKey.value = response.data.ambition.key;
      spAmbitionDescr.value = response.data.ambition.descr;

      spStrategyKey.value = response.data.strategy.key;
      spStrategyDescr.value = response.data.strategy.descr;

      spConditionKey.value = response.data.condition.key;
      spConditionDescr.value = response.data.condition.descr;
    } else {
      proxy.$toaster.error("Ophalen van de uitgangspuntendata is mislukt! " + response.error);
    }
  }
}

function startingpointUpdated() {
  console.log("StartingPointWidget, startingpointUpdated ");

  emits("update-startingpoint");
  fetchData();
}
</script>

<template>
  <v-layout class="widget-item">
    <header>
      <h2>Uitgangspunten maatregelen</h2>

      <div class="widget-actions">
        <information-dropdown>
          <template v-slot:header>
            <h2>Uitgangspunten maatregelen</h2>
          </template>
          <p>Geef hier uw ambitie, strategie en randvoorwaarde op. De slimme rekenkern berekent het meest optimale pakket om dit complex te verduurzamen.</p>
        </information-dropdown>
      </div>
    </header>
    <v-container pa-0>
      <v-row>
        <v-col>
          <v-row
            ><v-col cols="4"><strong>Ambitie</strong></v-col
            ><v-col>{{ spAmbitionDescr }}</v-col></v-row
          >
          <v-row>
            <v-col>
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-row
            ><v-col cols="4"><strong>Strategie</strong></v-col
            ><v-col>{{ spStrategyDescr }}</v-col></v-row
          >
          <v-row>
            <v-col>
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-row
            ><v-col cols="4"><strong>Randvoorwaarde</strong></v-col
            ><v-col>{{ spConditionDescr }}</v-col></v-row
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <!--
              <v-progress-linear
                value="70"
                height="40"
              >
                <strong>{{ 70 }}%</strong>
              </v-progress-linear>
              -->
        </v-col>
        <v-col>
          <starting-points-modal
            :partyAddressableObjectId="props.partyAddressableObjectId"
            :ambition="spAmbitionKey"
            :strategy="spStrategyKey"
            :condition="spConditionKey"
            @update-startingpoint="startingpointUpdated"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>
