import { render, staticRenderFns } from "./ComplexDetails.vue?vue&type=template&id=a15a483c"
import script from "./ComplexDetails.vue?vue&type=script&lang=ts&setup=true"
export * from "./ComplexDetails.vue?vue&type=script&lang=ts&setup=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a15a483c')) {
      api.createRecord('a15a483c', component.options)
    } else {
      api.reload('a15a483c', component.options)
    }
    module.hot.accept("./ComplexDetails.vue?vue&type=template&id=a15a483c", function () {
      api.rerender('a15a483c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/project/ComplexDetails.vue"
export default component.exports