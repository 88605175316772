var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "", "pa-0": "", "fill-height": "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "app-paris-proof-sidebar",
              attrs: {
                lg: "3",
                md: "4",
                sm: "12",
                order: "2",
                "order-md": "1",
              },
            },
            [
              _c("complex-net-heat-requirement", {
                attrs: { addressId: _vm.addressId },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "app-complex-detail",
              attrs: {
                lg: "9",
                md: "8",
                sm: "12",
                order: "1",
                "order-md": "2",
              },
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "building-info",
                      attrs: { cols: "12", lg: "7" },
                    },
                    [
                      _c(
                        "v-container",
                        { staticClass: "inner" },
                        [
                          _c("header", [
                            _c("h1", { staticClass: "small" }, [
                              _vm._v(_vm._s(_setup.building.description)),
                            ]),
                            _c("strong", [
                              _vm._v(_vm._s(_setup.building.ecmFunctionName)),
                            ]),
                          ]),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "ul",
                                    { staticClass: "building-details" },
                                    [
                                      _c("li", [
                                        _vm._v(_vm._s(_setup.building.address)),
                                      ]),
                                      _c("li", [
                                        _vm._v(
                                          _vm._s(_setup.building.postalCode) +
                                            " " +
                                            _vm._s(_setup.building.city)
                                        ),
                                      ]),
                                      _c("li", [
                                        _vm._v(_vm._s(_setup.building.country)),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "v-container",
                                    { attrs: { "pa-0": "" } },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "energy-scores",
                                          attrs: { noGutters: "" },
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("p", [_vm._v("Operationeel")]),
                                              _c("energy-label", {
                                                attrs: {
                                                  label:
                                                    _setup.building
                                                      .operationalLabel,
                                                  estimate:
                                                    _setup.building
                                                      .energyLabelIsEstimate,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            [
                                              _c("p", [_vm._v("Energielabel")]),
                                              _c("energy-label", {
                                                attrs: {
                                                  label:
                                                    _setup.building.energyLabel,
                                                  estimate:
                                                    _setup.building
                                                      .energyLabelIsEstimate,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("v-col", [
                                            _c("p", [_vm._v("Datascore")]),
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _setup.isLoading ===
                                                      false,
                                                    expression:
                                                      "isLoading === false",
                                                  },
                                                ],
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          _setup.hasDataAvailabilityMessages,
                                                        expression:
                                                          "hasDataAvailabilityMessages",
                                                      },
                                                    ],
                                                  },
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { right: "" },
                                                        scopedSlots: _vm._u([
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "div",
                                                                  _vm._g(
                                                                    {
                                                                      staticClass:
                                                                        "widget-chart building-data-score",
                                                                      staticStyle:
                                                                        {
                                                                          cursor:
                                                                            "help",
                                                                        },
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      "percentage-chart",
                                                                      {
                                                                        attrs: {
                                                                          width: 68,
                                                                          height: 68,
                                                                          percentage:
                                                                            _setup
                                                                              .building
                                                                              .dataScore
                                                                              .percent,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ]),
                                                      },
                                                      [
                                                        _c("div", [
                                                          _c("p", [
                                                            _vm._v(
                                                              "Dit ontbreekt er nog"
                                                            ),
                                                          ]),
                                                          _c(
                                                            "ul",
                                                            _vm._l(
                                                              _setup.building
                                                                .dataScore
                                                                .messages,
                                                              function (
                                                                msg,
                                                                index
                                                              ) {
                                                                return _c(
                                                                  "li",
                                                                  {
                                                                    key: index,
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _setup.msgDataScore(
                                                                          msg
                                                                        )
                                                                      )
                                                                    ),
                                                                    _c("br"),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          !_setup.hasDataAvailabilityMessages,
                                                        expression:
                                                          "!hasDataAvailabilityMessages",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "widget-chart building-data-score",
                                                  },
                                                  [
                                                    _c("percentage-chart", {
                                                      attrs: {
                                                        width: 68,
                                                        height: 68,
                                                        percentage:
                                                          _setup.building
                                                            .dataScore.percent,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { col: "5" } },
                                [
                                  _setup.isLoading === true
                                    ? _c("loader-spinner", {
                                        attrs: {
                                          "is-loading": _setup.isLoading,
                                        },
                                      })
                                    : _setup.building.mapdata
                                    ? _c(_setup.LocationMap, {
                                        attrs: {
                                          locationmapdata:
                                            _setup.building.mapdata,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "startingpoint",
                      attrs: { cols: "12", lg: "5" },
                    },
                    [
                      _c(
                        "v-layout",
                        { staticClass: "inner" },
                        [
                          _c(_setup.StartingPointWidget, {
                            attrs: { partyAddressableObjectId: _vm.addressId },
                            on: {
                              "update-startingpoint":
                                _setup.startingpointUpdated,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "overview-wrapper" },
                [
                  _c(
                    "v-col",
                    [
                      _c("building-applied-overview", {
                        key: _setup.refreshAppliedKey,
                        attrs: {
                          addressId: _vm.addressId,
                          ecmFunctionCode: _setup.building.ecmFunctionCode,
                          ecmFunctionName: _setup.building.ecmFunctionName,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "overview-wrapper" },
                [
                  _c("v-col", [
                    _c(
                      "article",
                      { staticClass: "inner" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "overview-filters align-items-start mt-4",
                          },
                          [
                            _c("filter-set", {
                              attrs: {
                                name: _setup.FilterTokenName.ComplexDetails,
                                filters: _setup.filters,
                              },
                              on: {
                                "filter-search": _setup.handleFilterSearch,
                                "filters-changed27":
                                  _setup.handleFiltersChanged,
                              },
                            }),
                          ],
                          1
                        ),
                        _setup.subLocationToken
                          ? _c(_setup.HomesGrid, {
                              attrs: { token: _setup.subLocationToken },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }