<script lang="ts" setup>
import { ref, watch, computed, onMounted, getCurrentInstance } from "vue";
import { getStartingPointOptions, calculateMeasures, severityCode } from "@/services/api/building.api";
import { useUserStore } from "@/stores/user";

const userStore = useUserStore();

const emits = defineEmits(["update-startingpoint"]);

const props = defineProps({
  partyAddressableObjectId: String,
  ambition: String,
  strategy: String,
  condition: String,
});

const dialog = ref(false);

const spAmbitionsList = ref([]);
const spStrategiesList = ref([]);
const spConditionsList = ref([]);

const ambitionKey = ref();
const strategyKey = ref();
const conditionKey = ref();

const disableButtons = ref(true);

onMounted(() => {
  fetchOptions();
});

const inputChanged = computed(
  () =>
    ambitionKey.value && strategyKey.value && (props.ambition !== ambitionKey.value || (props.strategy !== strategyKey.value && strategyKey.value != "None") || props.condition != conditionKey.value)
);

watch(
  () => dialog.value,
  () => {
    if (dialog.value) {
      if (props.ambition != "None") {
        ambitionKey.value = props.ambition;
      }
      if (props.strategy != "None") {
        strategyKey.value = props.strategy;
      }
      conditionKey.value = props.condition;

      disableButtons.value = false;
    }
  }
);

watch(
  () => props.ambition,
  () => {
    if (props.ambition != "None") {
      ambitionKey.value = props.ambition;
    }
  }
);
watch(
  () => props.strategy,
  () => {
    if (props.strategy != "None") {
      strategyKey.value = props.strategy;
    }
  }
);
watch(
  () => props.condition,
  () => {
    conditionKey.value = props.condition;
  }
);

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function fetchOptions() {
  const spResponse = await getStartingPointOptions();
  spAmbitionsList.value = spResponse.spAmbitionsList.filter((x) => x.key !== "None");
  spStrategiesList.value = spResponse.spStrategiesList.filter((x) => x.key !== "None");
  spConditionsList.value = spResponse.spConditionsList;
  console.log("StartingPointsModal, created, getStartingPointOptions: ", spResponse);
}

async function onSubmit() {
  disableButtons.value = true;
  const response = await calculateMeasures(props.partyAddressableObjectId, ambitionKey.value, strategyKey.value, conditionKey.value);
  if (response.severity <= severityCode.warning) {
    console.log("StartingPointsModal, calculateMeasures: ", response);
    proxy.$toaster.info(`Maatregelen toegevoegd: ${response.data.measuresAddedCount}`);
    emits("update-startingpoint");

    saveToFile(response.data.id, response.data);

    dialog.value = false;
  } else {
    disableButtons.value = false;

    saveToFile(response.data.id, response.data);

    console.log("StartingPointsModal, calculateMeasures problem: ", response.message);
    proxy.$toaster.error(`Probleem met rekenkern: ${response.message}`);
  }
}

async function onCancel() {
  proxy.$toaster.warning("Uitgangspunten wijzigen geannuleerd.");
  dialog.value = false;
}

function saveToFile(name, data) {
  if (userStore.hasShowComputeCoreValues) {
    const a = document.createElement("a");
    a.href = URL.createObjectURL(new Blob([JSON.stringify(data)], { type: "application/json" }));
    a.download = "rekenkern-" + name + ".json";
    a.click();
  }
}
</script>

<template>
  <v-dialog v-model="dialog" scrollable max-width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-btn dark v-bind="attrs" v-on="on" class="primary">
        <v-icon dark left>mdi-update</v-icon>
        Maatregelen genereren
      </v-btn>
    </template>
    <!--<form id="adm-addUser-form" @submit.prevent="onSubmit">-->
    <v-form>
      <v-card class="mx-auto">
        <v-card-title>
          <header class="app-welcome-message">
            <h2>Maatregelen berekenen</h2>
          </header>
          <v-spacer></v-spacer>
          <v-btn text @click="onCancel()">
            <v-icon dark>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-select
                  label="Ambitie"
                  :items="spAmbitionsList"
                  outlined
                  item-text="descr"
                  item-value="key"
                  v-model="ambitionKey"
                  hide-details
                  persistent-placeholder
                  placeholder="Kies een ambitie"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-select
                  label="Strategie"
                  :items="spStrategiesList"
                  outlined
                  item-text="descr"
                  item-value="key"
                  v-model="strategyKey"
                  hide-details
                  persistent-placeholder
                  placeholder="Kies een strategie"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-select label="Randvoorwaarde" :items="spConditionsList" outlined item-text="descr" item-value="key" v-model="conditionKey" hide-details persistent-placeholder />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row v-if="inputChanged">
              <v-col>
                <v-alert dense border="left" type="warning">Let op! <br />De bestaande maatregelen worden verwijderd door deze actie. </v-alert>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="auto">
                <v-btn class="primary" title="Opslaan" @click.prevent="onSubmit()" :dark="inputChanged && !disableButtons" :disabled="!inputChanged || disableButtons">
                  <v-icon dark left>mdi-checkbox-marked-circle</v-icon>
                  Berekenen en opslaan
                </v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-btn title="Annuleren" @click.prevent="onCancel()" :disabled="disableButtons">
                  <v-icon dark left>mdi-cancel</v-icon>
                  Annuleren
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
      <!--</form>-->
    </v-form>
  </v-dialog>
</template>
